import React from 'react';
// import EventListing from '../components/contentBlocks/EventListing';
import styled from 'styled-components';
import Layout from '../components/Layout';
import CForm, { SContactForm } from '../components/forms/ContactForm';
import Wrapper from '../styles/utilities/Wrapper';
import { colors } from '../styles/utilities/settings';

const Contact = ({ path }) => (
  <Layout title="Contact" path={path}>
    <Scontact>
      <Wrapper narrow>
        <CForm />
      </Wrapper>
    </Scontact>
  </Layout>
);

export default Contact;

const Scontact = styled.section`
  background-color: ${colors.gold};
  min-height: calc(100vh - 235px);
  margin: 0;
  padding: 50px 0 55px;
  display: flex;
  align-items: center;

  ${SContactForm} {
    max-width: 387px;
  }
`;
